import styled from "styled-components";
import { colors } from "../common/element/elements";

export const Styles = styled.div`
  .main {
    .circular-toggle {
      border-radius: 50%;
      overflow: hidden;
      background-color: ${colors.darkblue};
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
    .circular-toggle i {
      margin: 0; /* Remove default margin for the icon */
    }
    .dropdown-child {
      cursor: pointer;
    }
    .circular-toggle::after {
      display: none !important;
    }
    .partOne {
      .searchbar {
        .selectbox {
          @media (max-width: 768px) {
            font-size: 7px;
            width: 100%;
          }
        }
        .btn-find {
          background: ${colors.darkblue};
          border-radius: 10px;
          height: 40px;
          width: 150px;
        }
        .btn-find-small {
          background: ${colors.darkblue};
          border-radius: 10px;
          height: 40px;
          width: 50px;
          @media (max-width: 450px) {
            width: fit-content;
          }
        }
      }
      .right-nav {
        // background-color:${colors.darkblue};
        padding: 7px;
        border-radius: 10%;
        &:hover {
          // background-color: ${colors.darkbluehover};
          color: ${colors?.orange};
          cursor: pointer;
        }

        .avatar {
          background: ${colors.darkblue};
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          font-weight: 700;

          @media (max-width: 768px) {
            width: 20px;
            height: 20px;
          }
        }
        .logout-div {
          margin-top: 30px;
          visibility: hidden;
        }
      }
    }
    .right-nav:hover .logout-div {
      visibility: visible;
      margin-top: 70px;
      // margin-left: -10px;
    }
    .partTwo {
      background: ${colors.darkblue};
      div {
        .nav-btn {
          font-size: 1.1rem;
          font-weight: 500;
          @media (max-width: 996px) {
            font-size: 0.7rem;
          }
          @media (max-width: 576px) {
            font-size: 0.5rem;
          }
        }
        .nav-btn.active {
          border-bottom: 2px solid ${colors.orange};
          box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
        }
      }
    }
    ul.nav.menu-nav {
      li.nav-item {
        position: relative;

        a.nav-link {
          font-size: 14px;
          color: ${colors.black1};
          text-transform: uppercase;
          font-weight: 500;
          padding: 10px 10px 20px;

          i {
            font-size: 12px;
          }

          &:after {
            content: none;
          }

          &:hover {
            color: ${colors.darkblue};
          }

          @media (max-width: 1199px) {
            padding: 10px 2px 20px;
            letter-spacing: 0;
          }
        }

        ul.dropdown {
          position: absolute;
          right: 20%;
          top: 150%;
          min-width: 500px;
          height: 300px;
          overflow-y: scroll;
          border: 2px solid #dadada;
          text-align: left;
          padding: 0;
          border-radius: 5px;
          transition: 0.2s ease;
          opacity: 1;
          transform: scaleY(1);
          visibility: visible;
          z-index: 999;
          transform-origin: center top 0;
          // background-color: #fff;

          @media (max-width: 900px) {
            min-width: 300px;
            right: -10px;
          }

          li.nav-item {
            position: relative;

            a.nav-link {
              font-size: 13px;
              color: "yellow";
              padding: 10px 20px;
              text-transform: capitalize;
              font-weight: 400;
              margin-right: 0;
              border-bottom: 1px solid ${colors.border1};

              &:hover {
                color: ${colors.darkblue};

                i {
                  color: #fff;
                }
              }

              i {
                float: right;
                margin-top: 4px;
              }
            }

            &:last-child {
              margin-left: 0;

              a.nav-link {
                border-bottom: none;
              }
            }
          }
        }

        &:hover {
          ul.dropdown {
            opacity: 1;
            transform: scaleY(1);
            visibility: visible;
          }
        }
      }
    }
    .logo-image {
      width: 180px;
      @media (max-width: 1199px) {
        width: 150px;
      }
      @media (max-width: 768px) {
        width: 200px;
      }
    }
    .rs-toggle-presentation {
      background-color: #dadada !important; /* Change the color of the switch */
    }
    .rs-toggle-checked {
      .rs-toggle-presentation {
        background-color: black !important; /* Change the color of the switch */
      }
    }
    /* ToggleButton.css */

    .toggle-container {
      position: relative;
      width: 70px;
      height: 30px;
      cursor: pointer;
    }

    .toggle-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors?.darkblue};
      border-radius: 15px;
      transition: background-color 0.3s ease;
      box-shadow: inset 0px 0px 2px 2px rgb(137 128 128);
    }

    .toggle-button {
      position: absolute;
      top: 10%;
      left: 4%;
      width: 40%;
      height: 80%;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease;
      overflow: hidden;
    }

    .toggle-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      overflow: hidden;
    }

    /* Styles for the 'on' state */
    .toggle-container.on .toggle-background {
      background-color: ${colors?.orange};
    }

    .toggle-container.on .toggle-button {
      transform: translateX(130%);
    }
  }
  .lang-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: contain;
  }
  .logout-btn {
    svg {
      position: absolute;
      right: 20px;

      @media (max-width: 500px) {
        right: 10px;
      }
    }
  }
  .balance-blk {
    width: fit-content;
    align-self: end;
  }

  .rs-dropdown-item {
    &:hover {
      background: ${colors?.orange};
      color:#fff;
    }
  }
  .custom-avatar img {
  object-fit: contain;
}
`;
